export interface AppSettingsState {
  urlToReleaseNotesPage: string;
  urlToFAQPage: string;
  urlToFeedbackPage: string;
  dashboardEnabled: boolean;
  advisorBlockClientTier: number;
  blockHighValuedClientHelpUrl: string;
  tutorialVideosUrl: string;
  tutorialLifecycleVideoUrl: string;
  autoChatMessageDelay: number;
  isNewVersion: boolean;
  isnewVersionBannerHidden: boolean;
  sounds: SoundsSettings;
  fonts: FontsSettings;
  inChatMediaSendImagesEnabled: boolean;
  monthlyEarningsGoalWidgetEnabled: boolean;
  monthlyEarningsGoalWidgetLearnMoreUrl: string;
  googleAppId: string;
  fbAppId: string;
  mixpanelId: string;
  firebaseVapidKey: string;
  firebaseAPIKey: string;
  firebaseProjectId: string;
  firebaseSenderId: string;
  firebaseAppId: string;
  firestoreAppId: string;
  firestoreAPIKey: string;
  firestoreProjectId: string;
  firestoreSenderId: string;
  clientNameMinLength: number;
  clientNameMaxLength: number
}

export enum Ringing {
  Classic = 1,
  Modern = 2,
}

export interface SoundsSettings {
  ringing: Ringing;
  volume: number;
}

export enum FontSize {
  Small = 16,
  Medium = 18,
  Large = 22,
}

export interface FontsSettings {
  size: FontSize;
}