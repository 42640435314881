import { toUTCFormatDate } from "../../helpers/dateFormatHelper";
import api from "./api";
import axios from 'axios';
import * as loggly from '../logger';


export type ClientInfos = {
  notes : string;
  custom_dob : Date;
  note_title : string;
  updated_at: string;
}

export type NotesResponse = {
  title: NotesTitle;
  text: string;
  lastUpdated: Date | null;
};

export type NotesTitle = {
  clientName: string;
  birthDate: Date | null;
}

export function getNotes(clientId: number): Promise<NotesResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const request: Promise<any> = api.get<ClientInfos>(`/my/client_infos/${clientId}`, { cancelToken: source.token })
      .then(response => response.data)
      .catch(error => {
        loggly.error('Error fetching client info:', error);
        throw error;
      });


  return request.then(prepareNotesResponse);
}

export function updateNote(clientId: number, text: string): Promise<NotesResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return api
    .put<ClientInfos>(`/my/client_infos/${clientId}`, { notes :  text }, { cancelToken: source.token })
    .then(response => prepareNotesResponse(response.data));
}

export function updateClientDetails(
  clientId: number,
  clientName: string,
  birthDate: Date | null,
): Promise<NotesResponse> {
  return api
    .put<ClientInfos>(`/my/client_infos/${clientId}`, { note_title: clientName, custom_dob: toUTCFormatDate(birthDate) })
    .then(response => prepareNotesResponse(response.data));
}

const prepareNotesResponse = (info: ClientInfos): NotesResponse => {
  return {
    title: {
      birthDate: info.custom_dob ? new Date(info.custom_dob) : null,
      clientName: info.note_title,
    },
    text: info.notes || "",
    lastUpdated: info.updated_at && new Date(info.updated_at) || null
  };
}