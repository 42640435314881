import {
  takeEvery,
  put,
  select,
  call,
  fork,
  race,
  take,
  all,
  delay,
  takeLatest,
  cancel,
} from "redux-saga/effects";
import { replace } from "redux-first-history";
import { RoutesM } from "../router";
import { Howl } from "howler";
import { AxiosError, HttpStatusCode } from "axios";
import { Task } from "redux-saga";
import { retry } from "./extensions";
import closedSessionSound from "../assets/closed.mp3";
import hiredSound from "../assets/hired.mp3";
// Slices
import { closeModal, showAlertModal, showModal } from "../slices/modalSlice";
import * as UIActions from "../slices/uiSlice";
import * as NoteActions from "../slices/noteSlice";
import * as ChatActions from "../slices/chatSlice";
import * as UserActions from "../slices/userSlice";
import * as SessionActions from "../slices/sessionSlice";
import { clientLoaded } from "../slices/clientSlice";
// Selectors
import { userStatusSelector } from "../selectors/userSelectors";
import {
  clientSelector,
  closeReasonSelector,
  sessionReadingSessionIdSelector,
  sessionIdSelector,
  isSessionEndedSelector,
  isSessionStartedSelector,
  callingClientSelector,
  sessionStatusSelector,
} from "../selectors/sessionSelectors";
// Types
import {
  ChatSystemMessage,
} from "../types/chatTypes";
import {
  Client,
  SessionStatus,
} from "../types/sessionTypes";
import {
  ServerUserStatus,
} from "../types/userTypes";
import { ModalType } from "../types/modalTypes";
// Services
import { advisorChatHangup } from "../services/api/sessionApi";
import * as ClientApi from "../services/api/clientApi";
import * as NoteApi from "../services/api/noteApi";
import { AdvisorAcceptChatResponse, AdvisorStartChatResponse, acceptChat, queryAdvisorIncomingChat } from "../services/api/userApi";
import { getSessionReading, SessionReading } from "../services/api/messagesApi";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import {
  createIncomingChatNotification,
  createNotification,
} from "../services/desktopNotificationService";
import * as loggly from "../services/logger";
import { disconnectChatProvider } from "./pubNub";
import { initPubNubSession } from "../actions/pubNubActions";

const hiredAudio = new Howl({ src: hiredSound });
const closedSessionAudio = new Howl({ src: closedSessionSound });

const handleBeforeunload = (event: any) => {
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
};

function* getStartChatInfo(clientId: number) {
  const response: AdvisorStartChatResponse = yield call(queryAdvisorIncomingChat);
  
  loggly.log(`Client ${clientId} is asking to start`);
  const eventData: any = {
    "buyer id": response.buyer_id,
    "order id": response.order_id,
    "chat initial minutes": response.duration / 60,
    // "seconds from server send": (Math.abs(Date.now() - action.payload.serverSendTime.getTime()) / 1000).toFixed(0)
  };
  if (response.tryout) {
    eventData["tryout"] = true;
    eventData["free minutes"] = Math.round(response.duration / 60).toFixed(0);
  }
  mixpanelService.trackEvent(TrackEvents.ChatRing, eventData);
  return response;
}

function* handleClientAskToStart(action: ReturnType<typeof SessionActions.clientAskToStart>): any {
  let notification: Notification | null = null;

  try {
    const [response, answeredOnAnotherDevice]: [AdvisorStartChatResponse, any] = yield race([
      call(getStartChatInfo, action.payload.clientId),
      take(SessionActions.answeredOnAnotherDevice)
    ]);

    if (answeredOnAnotherDevice) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeunload);

    yield put(SessionActions.ringData(response));
    yield put(UIActions.startRinging());

    notification = createIncomingChatNotification(response.notification_text);

    const { missed } = yield race({
      accept: take(SessionActions.acceptSession.type),
      decline: take(SessionActions.declineSession.type),
      missed: take(SessionActions.missedSession.type),
      anotherDevice: take(SessionActions.answeredOnAnotherDevice.type),
    });

    if (missed) {
      yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Away, false));
      createNotification(response.missed_call_notification_text, undefined, true, "missed-chat");
    }
  } catch (e) {
    // try to avoid weird behavior when a new user recieves notifications from the old one
    yield put(SessionActions.declineSession());

    if (e instanceof AxiosError && e.response?.status === HttpStatusCode.NotFound) {
      loggly.error(e, { payload: action.payload });
      return;
    }

    throw e;
  } finally {
    if (notification) {
      notification.close();
    }

    yield put(UIActions.stopRinging());
  }
}

function* handleAcceptSession() {
  const acceptTask: Task = yield fork(acceptSession);

  const { declined, timeout } = yield race({
    started: take(SessionActions.sessionStarted),
    declined: take(SessionActions.declineSession),
    timeout: delay(5000)
  });

  if (declined || timeout) {
    yield cancel(acceptTask);
    yield call(disconnectChatProvider);
    yield put(SessionActions.sessionEnded());
  }
}

function* acceptSession() {
  yield put(clientLoaded(null)); // close client card, since after session data can be not actual
  loggly.log(`Session accepted`);

  try {
    const response: AdvisorAcceptChatResponse = yield call(acceptChat);
    const lastConsulted = response.last_order_submitted_at && new Date(response.last_order_submitted_at) || null;
    const isFavoriteClient = !!response.favorite;
    yield put(SessionActions.sessionClientDetailsLoaded(lastConsulted, isFavoriteClient));
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === HttpStatusCode.NotFound) {
      yield put(SessionActions.sessionEnded());
      return;
    }

    throw e;
  }

  yield put(replace(RoutesM.Init));
  yield put(initPubNubSession());
}

let hiredTask: Task;

function* handleSessionStarted(action: ReturnType<typeof SessionActions.sessionStarted>) {
  yield put(ChatActions.resetChatData());
  yield put(NoteActions.resetNoteData());
  yield put(NoteActions.noteSetIsLoading(true));
  yield put(closeModal());

  const client: Client = yield select(clientSelector);

  if (hiredTask) {
    yield cancel(hiredTask);
  }

  hiredTask = yield fork(hireUser, action.payload.freeDuration, false, action.payload.isTryout);

  yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Busy));
  yield put(replace(RoutesM.Session));

  const [notes, recentReadings]: [
    NoteApi.NotesResponse,
    ClientApi.GetLastReadingsResponse
  ] = yield all([
    retry(3, 10000, NoteApi.getNotes, client.id),
    retry(3, 10000, ClientApi.getLastReadings, client.id),
  ]);

  yield put(
    SessionActions.sessionLastReadingsLoaded(
      recentReadings.items,
      recentReadings.nextPage
    )
  );

  yield put(NoteActions.noteLoaded(notes));
}

function* handleSessionResumed(action: ReturnType<typeof SessionActions.sessionResumed>) {
  yield put(closeModal());
  hiredTask = yield fork(hireUser, action.payload.freeDuration, true, false);
}

function* hireUser(freeDurationInSeconds: number, isResuming: boolean, isTryout: boolean) {
  yield delay(freeDurationInSeconds * 1000);
  const isSessionEnded: boolean = yield select(isSessionEndedSelector);
  if (!isSessionEnded) {
    yield put(SessionActions.setUserHired(isResuming, isTryout));
  }
}

function* handleSessionPaused(action: ReturnType<typeof SessionActions.sessionPaused>) {
  yield put(
    showModal(ModalType.WaitingClient, {
      hangupTimeout: action.payload.hangupTimeout,
    })
  );
}


function* handleEndSession() {
  closedSessionAudio.play();
  loggly.log(`Session ended by expert`);
  //yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Available));
  window.removeEventListener("beforeunload", handleBeforeunload);
  yield put(ChatActions.updateClientIsTyping(false));
  const sessionId: string = yield select(sessionIdSelector);
  yield call(advisorChatHangup, sessionId);
}

function* handleSessionEnded() {
  loggly.log(`Session ended by server`);
  yield call(disconnectChatProvider);
  closedSessionAudio.play();
  const isSessionStarted: boolean = yield select(isSessionStartedSelector);
  if (!isSessionStarted) {
    yield put(replace(RoutesM.Clients));
    yield put(showAlertModal("", "The client has quit the session", "OK"));
    return;
  }

  if (hiredTask) {
    yield cancel(hiredTask);
  }
  yield put(closeModal());
  const status: ServerUserStatus = yield select(userStatusSelector);
  if (status === ServerUserStatus.Busy) {
    yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Available));
  }
  yield put(ChatActions.updateClientIsTyping(false));

  const closeReason: string | null = yield select(closeReasonSelector);
  yield put(
    ChatActions.addSystemMessage(
      new ChatSystemMessage(closeReason || "The client has quit the session.")
    )
  );
  window.removeEventListener("beforeunload", handleBeforeunload);
}

function* handleSessionClose() {
  yield put(replace(RoutesM.Clients));
}

function* handleMissedSession() {
  window.removeEventListener("beforeunload", handleBeforeunload);
}

function* handleDeclineSession() {
  window.removeEventListener("beforeunload", handleBeforeunload);
  
  const isSessionEnded: boolean = yield select(isSessionEndedSelector);
  if (isSessionEnded) {
    return;
  }
  
  const isSessionStarted: boolean = yield select(isSessionStartedSelector);
  if (!isSessionStarted) {
    console.log("Received decline session, but the session is not started.")
    return;
  }

  const { sessionEnded } = yield race({
    sessionEnded: take(SessionActions.sessionEnded.type),
    timeout: delay(3000),
  });

  if (!sessionEnded) {
    const sessionStatus: SessionStatus = yield select(sessionStatusSelector);
    const sessionId: string = yield select(sessionIdSelector);
    console.log(`Caught a rare case, when the advisor accepted a call in the same time when the client declines that call. Session status = ${sessionStatus}, session id = ${sessionId}`);

    mixpanelService.trackEvent(TrackEvents.PubNubConnectionError, {"session id": sessionId});
    yield put(replace(RoutesM.Clients));
    yield put(showAlertModal("", "Connection issue, session will be closed.", "OK"));
    yield put(SessionActions.sessionDeclined());
    yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Available));
    return;
  }
}

function* handleRealTimeActivity(action: ReturnType<typeof ChatActions.setRealTimeActivity>) {
  const device = extractDeviceType(action.payload.clientRealTimeActivity);
  if (device) {
    yield put(SessionActions.setClientDevice(device));
  }
}

function extractDeviceType(text: string) {
  if (/This member is coming from\s+Kasamba web site/.test(text)) {
    return "desktop";
  }
  if (/This member is coming from\s+Kasamba mobile/.test(text)) {
    return "mobile";
  }
  return "";
}

function* handleUserHired(action: ReturnType<typeof SessionActions.setUserHired>) {
  if (action.payload.isTryout) {
    return;
  }
  hiredAudio.play();
  let systemMessage = "Paid session has begun";
  if (action.payload.isResuming) {
    systemMessage = "The client has added minutes. You can now continue your session."
  }
  loggly.log(systemMessage);
  yield put(ChatActions.addSystemMessage(new ChatSystemMessage(systemMessage)));
}

function* handleDecreaseFeeFailed(action: ReturnType<typeof SessionActions.decreaseFeeFailed>) {
  yield put(showAlertModal("", action.payload, "OK"));
}

function* handleDecreaseFeeSucceeded(action: ReturnType<typeof SessionActions.decreaseFeeSucceeded>) {
  const newFee = (action.payload.feePerHour / 60).toFixed(2);
  yield put(
    ChatActions.addSystemMessage(
      new ChatSystemMessage(
        `Session fee has been set to $${newFee}/minute. Please note: Modifying your fee will affect this session only.`
      )
    )
  );
}

function* handleGetReading(action: ReturnType<typeof SessionActions.getReadingBySessionId>) {
  try {
    const client: Client | null = yield select(callingClientSelector);

    mixpanelService.trackEvent(TrackEvents.TranscriptClicked, {
      "client id": client?.id,
      "client name": client?.name,
      "order id": action.payload.sessionId
    });

    var loadedSessionId: number | undefined = yield select(
      sessionReadingSessionIdSelector
    );
    if (loadedSessionId === action.payload.sessionId) {
      yield put(SessionActions.showSessionReading(true));
    } else {
      yield put(SessionActions.startLoadingSessionReading());

      let sessionReading: SessionReading = yield retry(
        3,
        10000,
        getSessionReading,
        action.payload.sessionId
      );

      sessionReading.startedAt = action.payload.sessionStartTime;

      yield put(SessionActions.sessionReadingLoaded(sessionReading));
    }
  } catch (error: any) {
    loggly.error(error);
    yield put(SessionActions.sessionReadingError());
  }
}

function* handleCloseSessionReading() {
  yield put(SessionActions.showSessionReading(false));
}

export default function* sessionRoot() {
  yield takeEvery(SessionActions.clientAskToStart.type, handleClientAskToStart);
  yield takeEvery(SessionActions.acceptSession.type, handleAcceptSession);
  yield takeEvery(SessionActions.sessionStarted.type, handleSessionStarted);
  yield takeEvery(SessionActions.sessionResumed.type, handleSessionResumed);
  yield takeEvery(SessionActions.sessionPaused.type, handleSessionPaused);
  yield takeEvery(SessionActions.setUserHired.type, handleUserHired);
  yield takeEvery(SessionActions.endSession.type, handleEndSession);
  yield takeEvery(SessionActions.sessionEnded.type, handleSessionEnded);
  yield takeEvery(SessionActions.closeSession.type, handleSessionClose);
  yield takeEvery(SessionActions.declineSession.type, handleDeclineSession);
  yield takeEvery(SessionActions.missedSession.type, handleMissedSession);
  yield takeEvery(ChatActions.setRealTimeActivity.type, handleRealTimeActivity);
  yield takeEvery(SessionActions.decreaseFeeSucceeded.type, handleDecreaseFeeSucceeded);
  yield takeEvery(SessionActions.decreaseFeeFailed.type, handleDecreaseFeeFailed);
  yield takeLatest(SessionActions.getReadingBySessionId.type, handleGetReading);
  yield takeEvery(SessionActions.sessionReadingClose.type, handleCloseSessionReading);
}
